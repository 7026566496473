<template>
  <div class="jumbotron base-card-wh" v-bind:style="styles">
    <h1 class="display-3">
      {{ header }}
    </h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseCardWH",
  data() {
    return {
      styles: {
        "--background_color": this.backgroundColor,
        "--padding": this.padding,
        "--header_margin": this.header_margin,
        "--margin": this.margin,
        "--text_align": this.textAlign,
      },
    };
  },
  props: {
    header: {
      type: String,
      default: "Header",
    },
    backgroundColor: {
      type: String,
      default: "#D7F7E9",
    },
    padding: {
      type: String,
      default: "0 40px 20px 40px",
    },
    header_margin: {
      type: String,
      default: "0 -40px 20px -40px",
    },
    margin: {
      type: String,
      default: "0",
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.jumbotron.base-card-wh {
  background-color: $white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: var(--padding);
  margin: var(--margin);

  h1.display-3 {
    margin: var(--header_margin);
    font-family: "Lato";
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: var(--text_align);
    padding: 15px 25px;
    color: $dark;
    background-color: var(--background_color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
</style>
